import React from 'react';
import { table } from '../../styles';
import { useTheme } from 'emotion-theming';
import reasonCodeArray from '../../reasonCode/reasonCodeObject';

export default () => {
  const printTableRows = () => {
    // const rows = reasonCodeArray.forEach((key, index) => {
    //   return (

    //   );
    // });
    var rows = [];

    for (var i = 0; i < reasonCodeArray.length; i++) {
      rows.push(
        <tr>
          <td>
            <code>{reasonCodeArray[i].key}</code>
          </td>
          <td>
            <code>{reasonCodeArray[i].en}</code>
          </td>
          <td>
            <code>{reasonCodeArray[i].ar}</code>
          </td>
        </tr>
      );
    }
    return rows;
  };

  return (
    <div css={{ display: 'grid' }}>
      <div css={{ overflowX: 'auto' }}>
        <table css={table(useTheme())}>
          <thead>
            <tr>
              <th>transactionResponseCode</th>
              <th>transactionResponseMessage - en</th>
              <th>transactionResponseMessage - ar</th>
            </tr>
          </thead>
          <tbody>{printTableRows()}</tbody>
        </table>
      </div>
    </div>
  );
};
