import { useEffect } from 'react';

const useScript = (order, async) => {
  useEffect(() => {
    const placement = document.querySelector('.hideIframe');
    const script = document.createElement('script');
    script.setAttribute('id', 'kashier-iFrame');
    script.src = `https://checkout.kashier.io/kashier-checkout.js`;
    script.setAttribute('data-amount', order.amount);
    script.setAttribute('data-description', 'description');
    script.setAttribute('data-hash', order.hash);
    script.setAttribute('data-orderId', order.orderId);
    script.setAttribute('data-merchantId', order.mid);
    script.setAttribute('data-mode', order.mode);
    script.setAttribute('data-allowedMethods', order.allowedMethods);
    script.setAttribute('data-type', 'external');
    script.setAttribute('data-currency', order.currency);
    script.setAttribute('data-merchantRedirect', order.redirectUrl);
    script.setAttribute('data-redirectMethod', order.redirectMethod);
    script.setAttribute('data-brandColor', order.brandColor);
    script.setAttribute('data-display', order.display);
    script.setAttribute('type', 'application/javascript');
    script.async = typeof async === 'undefined' ? true : async;
    placement.appendChild(script);
    return () => {
      placement.removeChild(script);
    };
  }, [order]);
};

export default function Script({ order, async = true }) {
  useScript(order, async);

  return null; // Return null is necessary for the moment.
}
