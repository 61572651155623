import React from 'react';
import styled from '@emotion/styled';
import { onMobile, onTablet, isMobile } from '../../styles/responsive';
import Icon from './icon';

const QuickLinks = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 600px;
  padding: 16px 0;
  margin: 20px 0;
`;

const Flex = styled.div`
  display: flex;
`;

const IconContainer = styled.div`
  width: 50px;
  padding: 10px;
  margin-top: 8px;
  background: #00123b;
`;
const Heading = styled.h3`
  margin-top: 0;
`;

const Paragraph = styled.p`
  fontweight: 100;
  fontsize: 16px;
  color: ${(props) => props.theme.colors.font};
  lineheight: 27px;
`;

const Span = styled.span`
  display: inline-block;
  width: 50%;
  font-size: 16px;
  text-align: left;
  text-decoration: underline;
  margin-bottom: 20px;
  color: #06f;
  &::after {
    content: ' >';
    color: #06f;
  }
  @media (max-width: 415px) {
    width: 100%;
  }
`;
const Anchor = styled.a`
  color: #06f;
`;

export default ({ ...props }) => {
  return (
    <QuickLinks {...props} style={{ marginRight: '2%' }}>
      <Flex>
        <div style={{ marginRight: '20px', height: '100%' }}>
          <IconContainer>
            <Icon name={props.icon} color={props.iconColor} />
          </IconContainer>
        </div>
        <div>
          <div>
            <div style={{ marginBottom: '20px', padding: '0 0 0 0' }}>
              <Heading>{props.title}</Heading>
              <Paragraph>{props.paragraph}</Paragraph>
            </div>
          </div>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <Span>
              <Anchor href={props.link1}>{props.linkTitle1}</Anchor>
            </Span>
            <Span>
              <Anchor href={props.link2}>{props.linkTitle2}</Anchor>
            </Span>
            {props.linkTitle3 && (
              <Span>
                <Anchor href={props.link3}>{props.linkTitle3}</Anchor>
              </Span>
            )}
            {props.linkTitle4 && (
              <Span>
                <Anchor href={props.link4}>{props.linkTitle4}</Anchor>
              </Span>
            )}
          </div>
        </div>
      </Flex>
    </QuickLinks>
  );
};
