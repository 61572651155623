export default [
  {
    key: '00',
    en: 'Approved',
    ar: 'تمت الموافقة',
  },
  {
    key: '01',
    en: 'Your bank was not able to process the transaction',
    ar: 'البنك المصدر للبطاقة غير قادرًا على معالجة المعاملة',
  },
  {
    key: '02',

    en: "Refer to card issuer's special conditions",
    ar: 'يرجى الرجوع إلى جهة إصدار البطاقة',
  },
  {
    key: '03',

    en: 'Invalid merchant',
    ar: 'تاجر غير صالح',
  },
  {
    key: '04',
    en: 'Pick-up lost card detected',
    ar: 'إلتقط البطاقة ، تم الإبلاغ عن سرقتها أو ضياعها',
  },
  {
    key: '05',
    en: 'Do not honor, please retry or contact your issuer bank.',
    ar: 'غير مقبول، يرجى إعادة المحاولة أو الاتصال بالبنك المصدر بالبطاقة',
  },
  {
    key: '06',
    en: 'Error',
    ar: ' خطأ في الدفع',
  },
  {
    key: '07',
    en: 'Pick-up card, special condition',
    ar: 'إلتقط البطاقة ، تم الإبلاغ عن سرقتها أو ضياعها',
  },
  {
    key: '08',
    en: 'Honour with identification',
    ar: 'مقبول مع تحديد الهوية',
  },
  {
    key: '09',
    en: 'Request in progress',
    ar: 'طلب قيد التقدم',
  },
  {
    key: '10',
    en: 'Approved for partial amount',
    ar: 'تمت الموافقة على المبلغ الجزئي',
  },
  {
    key: '11',
    en: 'Approved (VIP)',
    ar: 'موافقة كبار الشخصيات',
  },
  {
    key: '12',
    en: 'Your bank was not able to process the transaction',
    ar: 'البنك المصدر للبطاقة غير قادرًا على معالجة المعاملة',
  },
  {
    key: '13',
    en: 'Invalid amount, please contact the merchant',
    ar: 'مبلغ غير صحيح ، يرجى الاتصال بالتاجر',
  },
  {
    key: '14',
    en: 'Invalid card information.',
    ar: 'بيانات البطاقة غير صحيحة.',
  },
  {
    key: '15',
    en: 'No such issuer',
    ar: 'لا يوجد مثل هذا المصدر',
  },
  {
    key: '16',
    en: 'Approved',
    ar: 'عملية مقبولة',
  },
  {
    key: '17',
    en: 'Customer cancellation',
    ar: 'إلغاء العميل',
  },
  {
    key: '18',
    en: 'Customer dispute',
    ar: 'نزاع العميل',
  },
  {
    key: '19',
    en: 'Re-enter transaction',
    ar: 'أعد إدخال المعاملة',
  },
  {
    key: '20',
    en: 'Invalid response',
    ar: 'استجابة غير صالحة',
  },
  {
    key: '21',
    en: 'No action taken',
    ar: 'لم يتم اتخاذ أي إجراء',
  },
  {
    key: '22',
    en: 'Suspected malfunction',
    ar: 'عطل مشتبه به',
  },
  {
    key: '23',
    en: 'Unacceptable transaction fee',
    ar: 'رسوم المعاملات غير مقبولة',
  },
  {
    key: '24',
    en: 'File update not supported by receiver',
    ar: 'تم رفض الدفع ',
  },
  {
    key: '25',
    en: 'Unable to locate record on file',
    ar: 'تم رفض الدفع ',
  },
  {
    key: '26',
    en: 'Duplicate file update record, old record replaced',
    ar: 'تم رفض الدفع ',
  },
  {
    key: '27',
    en: 'File update field edit error',
    ar: 'تم رفض الدفع ',
  },
  {
    key: '28',
    en: 'File update file locked out',
    ar: 'تم رفض الدفع ',
  },
  {
    key: '29',
    en: 'File update not successful, contact acquirer',
    ar: 'تم رفض الدفع ',
  },
  {
    key: '30',
    en: 'Format error',
    ar: 'خطأ في التنسيق',
  },
  {
    key: '31',
    en: 'Bank not supported by switch',
    ar: 'البنك غير مدعوم',
  },
  {
    key: '32',
    en: 'Completed partially',
    ar: 'اكتمل جزئيا',
  },
  {
    key: '33',
    en: 'Expired card',
    ar: 'بطاقة منتهية الصلاحية',
  },
  {
    key: '34',
    en: 'Suspected fraud',
    ar: 'يشتبه في الاحتيال',
  },
  {
    key: '35',
    en: 'Card acceptor contact acquirer',
    ar: 'عملية مرفوضة',
  },
  {
    key: '36',
    en: 'Restricted card, please contact your bank.',
    ar: 'بطاقة محظورة ، يرجى الاتصال بالبنك المصدر للبطاقة.',
  },
  {
    key: '37',
    en: 'Card acceptor call acquirer security',
    ar: 'عملية مرفوضة',
  },
  {
    key: '38',
    en: 'Allowable PIN tries exceeded',
    ar: 'تم تجاوز عدد محاولات PIN المسموح بها',
  },
  {
    key: '39',
    en: 'No credit account',
    ar: 'لا يوجد حساب ائتمان',
  },
  {
    key: '40',
    en: 'Requested function not supported',
    ar: 'الوظيفة المطلوبة غير مدعومة',
  },
  {
    key: '41',
    en: 'Lost card',
    ar: 'البطاقة مفقودة',
  },
  {
    key: '42',
    en: 'Your bank was not able to process the transaction',
    ar: 'البنك المصدر للبطاقة غير قادرًا على معالجة المعاملة',
  },
  {
    key: '43',
    en: 'Stolen card, pick-up',
    ar: 'بطاقة مسروقة',
  },
  {
    key: '44',
    en: 'No investment account',
    ar: 'لا يوجد حساب استثماري',
  },
  {
    key: '45',
    en: 'Declined, Contact card issuer.',
    ar: 'عملية مرفوضة ، يرجى الاتصال بجهة مصدر البطاقة.',
  },
  {
    key: '46-50',
    en: 'Declined, Contact card issuer.',
    ar: 'عملية مرفوضة ، يرجى الاتصال بجهة مصدر البطاقة.',
  },
  {
    key: '51',
    en: 'Insufficient funds',
    ar: 'رصيد البطاقة غير كاف',
  },
  {
    key: '52',
    en: 'No checking account',
    ar: 'لا يوجد حساب جاري',
  },
  {
    key: '53',
    en: 'No savings account',
    ar: 'لا يوجد حساب توفير',
  },
  {
    key: '54',
    en: 'Expired card',
    ar: 'بطاقة منتهية الصلاحية',
  },
  {
    key: '55',
    en: 'Declined - Wrong PIN Entered by Card Holder',
    ar: 'مرفوض - إدخال خاطئ لرقم التعريف الشخصي بواسطة حامل البطاقة',
  },
  {
    key: '56',
    en: 'No card record',
    ar: 'لا يوجد سجل للبطاقة',
  },
  {
    key: '57',
    en: 'Online transactions are not permitted to this card, please contact the card issuer.',
    ar: 'المعاملات عبر الإنترنت غير مسموح بها لهذه البطاقة ، يرجى الاتصال بالبنك مصدر البطاقة.',
  },
  {
    key: '58',
    en: 'Online transactions are not permitted to this card, please contact the card issuer.',
    ar: 'المعاملات عبر الإنترنت غير مسموح بها لهذه البطاقة ، يرجى الاتصال بالبنك مصدر البطاقة.',
  },
  {
    key: '59',
    en: 'Suspected fraud',
    ar: 'تم رفض الدفع بسبب إشتباه في إحتيال',
  },
  {
    key: '60',
    en: 'Card acceptor contact acquirer',
    ar: 'تم رفض الدفع ',
  },
  {
    key: '61',
    en: 'Exceeds withdrawal amount limit',
    ar: 'تم رفض الدفع بسبب تجاوز حدود المبلغ المسموح للدفع',
  },
  {
    key: '62',
    en: 'Your bank was not able to process the transaction',
    ar: 'البنك المصدر للبطاقة غير قادرًا على معالجة المعاملة',
  },
  {
    key: '63',
    en: 'Your bank was not able to process the transaction',
    ar: 'البنك المصدر للبطاقة غير قادرًا على معالجة المعاملة',
  },
  {
    key: '64',
    en: 'Original amount incorrect',
    ar: 'المبلغ الأصلي غير صحيح',
  },
  {
    key: '65',
    en: 'Exceeds withdrawal frequency limit',
    ar: 'تم إجتياز حد تردد السحب',
  },
  {
    key: '66',
    en: "Card acceptor call acquirer's security department",
    ar: 'التقط البطاقة من الجهاز',
  },
  {
    key: '67',
    en: 'Hard capture (requires that card be picked up at ATM)',
    ar: 'الالتقاط الثابت (يتطلب استلام البطاقة من جهاز الصراف الآلي)',
  },
  {
    key: '68',
    en: 'Response received too late',
    ar: 'رد متأخر',
  },
  {
    key: '69-74',
    en: 'Reserved for ISO use',
    ar: 'عملية مرفوضة',
  },
  {
    key: '75',
    en: 'Allowable number of PIN tries exceeded',
    ar: 'تم تجاوز العدد المسموح به من محاولات PIN',
  },
  {
    key: '76-77',
    en: 'Reserved for private use',
    ar: 'عملية مرفوضة',
  },
  {
    key: '78',
    en: 'Declined - No Account',
    ar: 'مرفوض - لا يوجد حساب',
  },
  {
    key: '79-89',
    en: 'Reserved for private use',
    ar: 'عملية مرفوضة',
  },
  {
    key: '90',
    en:
      "Cutoff is in process (switch ending a day's business and starting the next. Transaction can be sent again in a few minutes)",
    ar: 'قطع قيد التنفيذ',
  },
  {
    key: '91',
    en: 'Your bank was not able to process the transaction',
    ar: 'البنك المصدر للبطاقة غير قادرًا على معالجة المعاملة',
  },
  {
    key: '92',
    en: 'Financial institution or intermediate network facility cannot be found for routing',
    ar: 'لا يمكن العثور على مؤسسة مالية أو مرفق شبكة وسيطة للتوجيه',
  },
  {
    key: '93',
    en: 'Transaction cannot be completed. Violation of law',
    ar: 'لا يمكن إتمام العملية بسبب انتهاك للقانون',
  },
  {
    key: '94',
    en: 'Duplicate transmission',
    ar: 'انتقال مكرر',
  },
  {
    key: '95',
    en: 'Reconcile error',
    ar: 'خطأ في التصالح',
  },
  {
    key: '96',
    en: 'Your bank was not able to process the transaction',
    ar: 'البنك المصدر للبطاقة غير قادرًا على معالجة المعاملة',
  },
  {
    key: '97',
    en: 'Declined - CVV MisMatch',
    ar: 'عملية مرفوضة، رمز الأمان غير مطابق.',
  },
  {
    key: '98',
    en: 'Declined',
    ar: 'عملية مرفوضة',
  },
  {
    key: '99',
    en: 'Declined',
    ar: 'عملية مرفوضة',
  },
  {
    key: 'AA',
    en: 'UNSPECIFIED FAILURE',
    ar: 'فشل غير محدد',
  },
  {
    key: 'BB',
    en: 'ACQUIRER SYSTEM ERROR',
    ar: 'خطأ في نظام البنك',
  },
  {
    key: 'CC',
    en: 'UNKNOWN',
    ar: 'غير معلوم',
  },
  {
    key: 'U',
    en: 'Authentication is not available',
    ar: 'المصادقة غير متوفرة',
  },
  {
    key: 'X',
    en: 'Authentication is not available',
    ar: 'المصادقة غير متوفرة',
  },
  {
    key: 'P',
    en: 'Authentication failed, please check your card info.',
    ar: 'المصادقة فشلت، خطأ في بيانات الكارت.',
  },
  {
    key: 'N',
    en: 'Authentication failed',
    ar: 'المصادقة فشلت',
  },
  {
    key: 'S',
    en: 'Invalid Signature on Authentication Response',
    ar: 'توقيع غير صالح في استجابة المصادقة',
  },
  {
    key: 'I',
    en: 'MPI Processing Error',
    ar: 'MPI Processing Error',
  },
  {
    key: 'M',
    en: 'Authentication Attempted (No CAVV)',
    ar: 'Authentication Attempted (No CAVV)',
  },
  {
    key: 'BLOCKED',
    en: 'Your bank was not able to process the transaction',
    ar: 'البنك المصدر للبطاقة غير قادرًا على معالجة المعاملة',
  },
  {
    key: 'k_default',
    en: 'A General Error Occured, please contact support.',
    ar: 'حدث خطأ عام ، يرجى الاتصال بالدعم.',
  },
  {
    key: 'k_0',
    en: 'Invalid detoken parameter.',
    ar: 'رمز غير صالح',
  },
  {
    key: 'k_2',
    en: 'Token not found.',
    ar: 'لم يتم العثور على الرمز',
  },
  {
    key: 'k_2',
    en: 'Token expired after 10 minutes of inactivity.',
    ar: 'انتهت صلاحية الرمز بعد 10 دقائق من عدم النشاط.',
  },
  {
    key: 'k_3',
    en: 'card security code does not exist.',
    ar: 'رمز أمان البطاقة غير موجود.',
  },
  {
    key: ' gw_0',
    en: ' gateway error , contact support.',
    ar: ' خطأ في استجابة بوابة الدفع ، اتصل بالدعم.',
  },
];
