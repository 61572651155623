export default {
  blue: '#d0f1fd',
  blueLight: '#e9f7ff',
  blueDark: '#264D99',
  grayDark:'3d3d3d',
  red: '#E40046',
  // redLight: "#FCE7EE",
  redLight: '#FAD0DD',
  violet: '#A05EB5',
  white: 'fff',
  green: '#00bcbc',
  greenLight: '#D0EBE1',
  greenLight2: '#EDFAFA',
  yellow: '#FFC72C',
  warningIcon:'#EF9400',
  warningBackground: '#FEF4E5',
  orange: '#ED8B00',
  orangeLight: '#FBE9D0',
  originPeach:'#FF9D6C',
  white: '#FFFFFF',
  black: '#000000',
  gray: '#5C6975',
  grayLight: '#AEAEAE',
  grayDark: '#3B4656',
};
