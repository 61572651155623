import React from 'react';
import styled from '@emotion/styled';
import { useTheme } from 'emotion-theming';
import { ArrowRight } from 'react-feather';
import { shadowAround } from '../../styles';
import Link from '../Link';

const GetStarted = styled.div`
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  margin: 15px 0;
  color: ${(props) => props.theme.colors.primary};
  border: 1px solid ${(props) => props.theme.colors.primary};
  border-radius: 5px;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  position: relative
  transition: ${(props) => props.theme.transitions.hover};
  &:hover {
    background-color:#f8f8f8;
  }
  &:focus: {
      
  }
`;

export default ({ url, title }) => {
  return (
    <Link to={url}>
      <GetStarted>
        <div style={{ display: 'flex' }}>
          <span> {title} </span>
          <ArrowRight />
        </div>
      </GetStarted>
    </Link>
  );
};
