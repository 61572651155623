import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import createHmac from 'create-hmac';
import Popup from './popup';
import Script from './useScript';
import AnchorTag from './anchor';
import Highlights from './highlights';

const Form = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
  div,
  h2,
  form,
  input,
  p {
    padding: 0;
    margin: 0;
    outline: none;
    font-size: 16px;
    color: #666;
  }
  h2 {
    padding: 10px 0;
    font-size: 32px;
    font-weight: 300;
    text-align: center;
  }
  h4 {
    margin: 20px 0 8px 0;
    font-size: 14px;
    font-weight: 400;
  }
  .first {
    font-weight: 600;
    margin: 20px 0 14px 0;
  }
  p {
    font-size: 12px;
  }
  hr {
    color: #a9a9a9;
    opacity: 0.3;
    margin: 0 50px 0 -16px;
  }
  .main-block {
    max-width: 480px;
    min-height: 350px;
    padding: 10px 0;
    margin: auto;
    border-radius: 5px;
    box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.22);
    background: #f6f7fb;
  }
  form {
    margin: 0 0 0 60px;
  }
  .paymentUI-type {
    margin: 15px 0;
  }
  .method {
    margin: 15px 0;
  }
  input[type='radio'],
  input[type='checkbox'] {
    display: none;
  }
  label {
    margin: 0;
    border-radius: 5px 0 0 5px;
  }
  label.radio,
  label.checkbox {
    position: relative;
    display: inline-block;
    padding-top: 4px;
    margin-right: 20px;
    margin-bottom: 10px;
    text-indent: 30px;
    overflow: visible;
    font-size: 14px;
    cursor: pointer;
  }
  label.radio:before {
    content: '';
    position: absolute;
    top: 2px;
    left: 0;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 2px solid #00bcbc;
    background: #fff;
  }
  label.checkbox:before {
    content: '';
    position: absolute;
    top: 2px;
    left: 0;
    border-radius: 5px;
    border: 1px solid #d6d6d6;
    width: 20px;
    height: 20px;
    background: #fff;
  }
  label.checkbox:after {
    content: '';
    position: absolute;
    width: 9px;
    height: 4px;
    top: 7px;
    left: 4px;
    border: 3px solid #fff;
    border-top: none;
    border-right: none;
    transform: rotate(-45deg);
    opacity: 0;
  }
  label.radio:after {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    top: 8px;
    left: 6px;
    border-radius: 50%;
    background: #00bcb4;
    border-right: none;
    opacity: 0;
  }
  .input-container {
    margin: 10px 0 15px 0;
  }
  .labelForText {
    font-size: 14px;
    font-weight: 400;
    display: block;
  }
  input[type='radio']:checked + label:after,
  input[type='checkbox']:checked + label:after {
    opacity: 1;
  }
  input[type='checkbox']:checked + label:before {
    background: #00bcb4;
  }
  input[type='text'],
  input[type='url'],
  input[type='color'] {
    width: calc(100% - 57px);
    height: 36px;
    margin: 6px 0 10px -5px;
    padding-left: 10px;
    border-radius: 5px;
    border: solid 1px #cbc9c9;
    box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.09);
    background: #fff;
  }
  input[type='text'],
  input[type='url'] {
    margin-bottom: 15px;
  }
  input[type='text']:focus,
  input[type='url']:focus {
    border: solid 2px #7fbffc;
  }
  input:read-only {
    border: none;
    border-radius: none;
    box-shadow: none;
    cursor: context-menu;
    background-color: #dfdfdffa;
  }
  input:read-only:focus {
    border: none;
  }
  #icon {
    display: inline-block;
    padding: 9.3px 15px;
    box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.09);
    background: #00bcbc;
    color: #fff;
    text-align: center;
  }
  .btn-block {
    margin-top: 10px;
    text-align: center;
  }
  button {
    width: 30%;
    padding: 10px 0;
    margin: 10px auto;
    border-radius: 5px;
    border: none;
    background: #00bcbc;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
  }
  button:hover {
    background: #00bcbcbf;
    cursor: pointer;
  }
  .center {
    margin: 0 50px 0 0;
  }
  .kashier-payment {
    display: none;
  }
  .kashier-button {
    color: #00bcb4;
    margin: auto;
    text-align: center;
    cursor: pointer;
    font-size: 14px;
    margin: 14px 0 21px 0;
  }

  .buttonText:hover {
    background: rgba(2, 209, 200, 0.08);
    padding: 10px;
    border-radius: 5px;
  }
  .select {
    width: calc(100% - 57px);
    height: 36px;
    margin: 0 0 20px -5px;
    padding-left: 10px;
    border-radius: 5px;
    border: solid 1px #cbc9c9;
    box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.09);
    background: #fff;
    cursor: pointer;
    line-height: 1.1;
    color: #666;
    background-image: linear-gradient(to top, #f9f9f9, #fff 33%);
    align-items: center;
    font-size: 16px;
  }
  .select::after {
    content: '';
    width: 0.8em;
    height: 0.5em;
    background-color: #00bcbc;
    clip-path: polygon(100% 0%, 0 0%, 50% 100%);
    justify-self: end;
  }
  select:focus + .focus {
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    border: 2px solid #00bcbc;
    border-radius: inherit;
  }
  .color-selector {
    display: inline-block;
    margin: 6px auto 15px;
    border-radius: 3px;
    position: relative;
    padding: 6px 32px 6px 10px;
    font-family: verdana;
    background: white;
    box-shadow: 1px 2px 5px rgb(0 0 0 / 9%);
    width: calc(100% - 57px);

    &::after {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 5px 0 5px;
      border-color: black transparent transparent transparent;
      position: absolute;
      right: 10px;
      top: 17px;
    }

    .circle {
      display: inline-block;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: 1px solid #eee;
      margin-right: 10px;
    }

    .hidden {
      position: absolute;
      left: 0;
      opacity: 0;
    }

    span {
      display: inline-block;
      vertical-align: middle;
    }
  }
 .how-to{
   display: block;
   font-weight: 400;
   width: calc(100% - 57px);
   font-size: 12px;
   margin-top: -10px;
   color: #ababbe;
 }
}
`;

const PaymentUIBuilder = ({ children }) => {
  const [configData, setConfigData] = useState({
    paymentUI: 'iframe',
    mid: '',
    apikey: '',
    orderId: '',
    methods: [''],
    redirectUrl: '',
    amount: '',
    currency: 'EGP',
    hash: '',
    redirect: 'get',
    redirectFailure: 'false',
    brandColor: '#00bcbc',
    display: 'en',
    mode: '',
  });

  const [showKashier, setShowKashier] = useState(false);
  const [hppsrc, setHppSrc] = useState(null);
  const [iframeParams, setIframeParams] = useState(null);
  const [result, setResult] = useState(null);
  const [showCode, setShowCode] = useState(false);
  const [child, setChild] = useState(null);
  const [showErorr, setShowError] = useState(false);

  useEffect(() => {
    const paymentStatus = window.location.href.split('?')[1]
      ? window.location.href.split('?')[1].split('&')[0].split('=')[1]
      : null;
    const transactionId = window.location.href.split('?')[1]
      ? window.location.href.split('?')[1].split('&')[7].split('=')[1]
      : null;
    if (paymentStatus) {
      if (paymentStatus == 'SUCCESS') {
        setResult({
          status: 'SUCCESS',
          transactionId: transactionId,
        });
      } else {
        setResult({
          status: 'FAILURE',
          transactionId: transactionId,
        });
      }
    }
  }, []);

  const onChange = (e) => {
    if (e.target.name.search('Method') != -1) {
      if (configData.methods.includes(e.target.value)) {
        setConfigData({
          ...configData,
          methods: configData.methods.filter((method) => method != e.target.value),
        });
      } else {
        setConfigData({
          ...configData,
          methods: [...configData.methods, e.target.value],
        });
      }
    } else {
      setConfigData({
        ...configData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const methodsChecked = (name) => {
    if (name === 'allMethod') {
      if (configData.methods.includes('')) {
        return true;
      } else {
        return false;
      }
    } else if (name === 'cardMethod') {
      if (configData.methods.includes('card')) {
        return true;
      } else {
        return false;
      }
    } else if (name === 'walletMethod') {
      if (configData.methods.includes('wallet')) {
        return true;
      } else {
        return false;
      }
    } else if (name === 'bankInstallmentMethod') {
      if (configData.methods.includes('bank_installment')) {
        return true;
      } else {
        return false;
      }
    }
  };
  const onSubmit = (e) => {
    e.preventDefault();
    const redirectUrl = 'https://developers.kashier.io/payment/paymentbuilder';
    const path = `/?payment=${configData.mid}.${configData.orderId}.${configData.amount}.${configData.currency}`;
    const hash = createHmac('sha256', configData.apikey).update(path).digest('hex');
    setConfigData({
      ...configData,
      hash,
    });
    children = {
      ...children,
      props: {
        ...children.props,
        children: children.props.children.map((child) => {
          return {
            ...child,
            props: {
              ...child.props,
              children: child.props.children.map((child) => {
                return {
                  ...child,
                  props: {
                    ...child.props,
                    configdata: {
                      ...configData,
                      hash: hash,
                    },
                  },
                };
              }),
            },
          };
        }),
      },
    };
    setChild(children);
    setShowCode(true);
    setShowKashier(true);

    if (configData.paymentUI == 'iframe') {
      const iframe = document.getElementById('el-kashier-button');
      if (iframe) {
        iframe.remove();
      }
      setIframeParams({
        mid: configData.mid,
        orderId: configData.orderId,
        currency: configData.currency,
        amount: configData.amount,
        allowedMethods: configData.methods,
        redirectUrl: configData.redirectUrl,
        redirectMethod: configData.redirect,
        display: configData.display,
        brandColor: configData.brandColor,
        hash: hash,
        mode:configData.mode,
        redirectUrl: redirectUrl,
      });
    } else {
      setHppSrc(
        `https://checkout.kashier.io?merchantId=${configData.mid}&mode=test&orderId=${
          configData.orderId
        }&amount=${configData.amount}&currency=${configData.currency}&hash=${hash}&allowedMethods=${
          configData.methods
        }&merchantRedirect=${configData.redirectUrl}&failureRedirect=${
          configData.redirectFailure
        }&redirectMethod=${configData.redirect}&brandColor=${encodeURIComponent(
          configData.brandColor
        )}&display=${configData.display}`
      );
    }
    var kashier = document.getElementById('kashier-payment');
    kashier.classList.remove('kashier-payment');
  };

  const onClickCheckout = () => {
    var match = configData.mid.match(/^MID-[0-9]+-[0-9]+$/g);
    if (match) {
      if (configData.paymentUI == 'iframe') {
        const iframe = document.getElementById('el-kashier-button');
        if (iframe) {
          iframe.click();
          // setTimeout(() => {
          //   console.log(
          //     document
          //       .getElementsByTagName('iframe')[0]
          //       .contentWindow.document.querySelector('loader')
          //   );
          //   if (!document.getElementsByClassName('loader')) {
          //     const iframeElements = document.getElementsByTagName('iframe');
          //     for (var i = 0; i < iframeElements.length; i++) {
          //       if (iframeElements[i].src.includes('checkout.kashier')) {
          //         iframeElements[i].style.display = 'none';
          //       }
          //     }
          //     setShowError(true);
          //     window.scrollTo(0, 0);
          //     setTimeout(() => {
          //       setShowError(false);
          //     }, 5000);
          //   }
          // }, 15000);
        }
      }
      if (configData.paymentUI == 'hpp') {
        const hpp = document.getElementById('hppOpen');
        if (hpp) hpp.click();
      }
    } else {
      setShowError(true);
      window.scrollTo(0, 0);
      setTimeout(() => {
        setShowError(false);
      }, 5000);
    }
  };

  return (
    <>
      {showErorr ? <Highlights.Error>Invalid Merchant ID </Highlights.Error> : null}
      <Form>
        <div className="main-block">
          <h2>PaymentUI Test Builder</h2>
          <form onSubmit={(e) => onSubmit(e)}>
            <div>
              <hr />
              <div className="input-container">
                <label htmlFor="mid" className="labelForText">
                  Merchant ID
                </label>
                <input
                  type="text"
                  name="mid"
                  id="mid"
                  value={configData.mid}
                  onChange={(e) => onChange(e)}
                  required
                />
                <span className="how-to">
                  Learn more about how to obtain your{` `}
                  <AnchorTag href="/gettingstarted/apikeys#obtainingyourkeys&mid">
                    merchant ID
                  </AnchorTag>
                </span>
              </div>
              <div className="input-container">
                <label htmlFor="apikey" className="labelForText">
                  API Key
                </label>
                <input
                  type="text"
                  name="apikey"
                  id="apikey"
                  value={configData.apikey}
                  onChange={(e) => onChange(e)}
                  required
                />
                <span className="how-to">
                  Learn more about how to obtain your{` `}
                  <AnchorTag href="/gettingstarted/apikeys#obtainingyourkeys&mid">
                    payment API key
                  </AnchorTag>
                </span>
              </div>
              <div className="input-container">
                <label htmlFor="orderId" className="labelForText">
                  Order ID
                </label>
                <input
                  type="text"
                  name="orderId"
                  id="orderId"
                  value={configData.orderId}
                  onChange={(e) => onChange(e)}
                  required
                />
                <span className="how-to">
                  Unique order using as reference between merchant and kashier
                </span>
              </div>
              <hr />
              <div className="method">
                <h4 className="first">Payment Method</h4>
                <input
                  type="checkbox"
                  value=""
                  id="allMethod"
                  name="allMethod"
                  checked={methodsChecked('allMethod')}
                  onChange={(e) => onChange(e)}
                />
                <label htmlFor="allMethod" className="checkbox">
                  All Methods
                </label>
                <input
                  type="checkbox"
                  value="card"
                  id="cardMethod"
                  name="cardMethod"
                  checked={methodsChecked('cardMethod')}
                  onChange={(e) => onChange(e)}
                />
                <label htmlFor="cardMethod" className="checkbox">
                  Card
                </label>
                <input
                  type="checkbox"
                  value="wallet"
                  id="walletMethod"
                  name="walletMethod"
                  checked={methodsChecked('walletMethod')}
                  onChange={(e) => onChange(e)}
                />
                <label htmlFor="walletMethod" className="checkbox">
                  Wallet
                </label>
                <input
                  type="checkbox"
                  value="bank_installment"
                  id="bankInstallmentMethod"
                  name="bankInstallmentMethod"
                  checked={methodsChecked('bankInstallmentMethod')}
                  onChange={(e) => onChange(e)}
                />
                <label htmlFor="bankInstallmentMethod" className="checkbox">
                  Bank Installment
                </label>
              </div>
              <hr />
              <div className="input-container">
                <label htmlFor="amount" className="labelForText">
                  Amount
                </label>
                <input
                  type="text"
                  name="amount"
                  id="amount"
                  value={configData.amount}
                  onChange={(e) => onChange(e)}
                  required
                />
              </div>
              <div className="input-container">
                <label htmlFor="currency" className="labelForText">
                  Currency
                </label>
                <input
                  type="text"
                  name="currency"
                  id="currency"
                  value={configData.currency}
                  onChange={(e) => onChange(e)}
                  required
                  readOnly
                />
              </div>
              <div className="input-container">
                <label htmlFor="redirectUrl" className="labelForText">
                  Redirect URL
                </label>
                <input
                  type="url"
                  name="redirectUrl"
                  id="redirectUrl"
                  value={configData.redirectUrl}
                  onChange={(e) => onChange(e)}
                />
              </div>
              <hr />
              <h4>Redirect Method</h4>
              <div>
                <select
                  className="select focus"
                  name="redirect"
                  id="redirect"
                  onChange={(e) => onChange(e)}
                  value={configData.redirect}
                >
                  <option value="get">Get</option>
                  <option value="post">Post</option>
                </select>
              </div>
              <h4>Redirect Failure</h4>
              <div>
                <select
                  className="select focus"
                  name="redirectFailure"
                  id="redirectFailure"
                  onChange={(e) => onChange(e)}
                  value={configData.redirectFailure}
                >
                  <option value="true">True</option>
                  <option value="false">False</option>
                </select>
              </div>
              <hr />
              <h4>Display Language</h4>
              <div>
                <select
                  className="select focus"
                  name="display"
                  id="display"
                  onChange={(e) => onChange(e)}
                  value={configData.display}
                >
                  <option value="en">English</option>
                  <option value="ar">Arabic</option>
                </select>
              </div>
              <hr />
              <h4>Display Mode</h4>
              <div>
                <select
                  className="select focus"
                  name="mode"
                  id="mose"
                  onChange={(e) => onChange(e)}
                  value={configData.mode}
                >
                  <option value="test">Test</option>
                  <option value="live">Live</option>
                </select>
              </div>
              <hr />

              <div className="input-container">
                <label htmlFor="brandColor" className="labelForText">
                  Brand Color
                </label>
                <div className="color-selector">
                  <span className="circle" style={{ background: configData.brandColor }} />
                  <span>{configData.brandColor}</span>
                  <input
                    type="color"
                    name="brandColor"
                    id="brandColor"
                    className="hidden"
                    value={configData.brandColor}
                    onChange={(e) => onChange(e)}
                  />
                </div>
              </div>
              <hr />
              <div className="paymentUI-type">
                <h4 className="first">Select paymentUI type</h4>
                <input
                  type="radio"
                  value="iframe"
                  id="iframe"
                  name="paymentUI"
                  checked={configData.paymentUI == 'iframe'}
                  onChange={(e) => onChange(e)}
                />
                <label htmlFor="iframe" className="radio">
                  I-frame
                </label>
                <input
                  type="radio"
                  value="hpp"
                  id="hpp"
                  name="paymentUI"
                  checked={configData.paymentUI == 'hpp'}
                  onChange={(e) => onChange(e)}
                />
                <label htmlFor="hpp" className="radio">
                  Hosted Payment Page
                </label>
              </div>
              <hr />
              <div className="center">
                <div className="btn-block">
                  <button type="submit">Generate</button>
                </div>
                <div className="kashier-payment" id="kashier-payment" onClick={onClickCheckout}>
                  <div className="kashier-button">
                    <span className="buttonText">Preview Payment UI</span>
                  </div>
                  {showKashier ? (
                    configData.paymentUI === 'hpp' ? (
                      <>
                        <a href={hppsrc} target="_blank" id="hppOpen"></a>{' '}
                      </>
                    ) : (
                      <>
                        <div className="hideIframe" style={{ display: 'none' }}>
                          <Script order={iframeParams} />
                        </div>
                      </>
                    )
                  ) : null}
                </div>
              </div>
            </div>
          </form>
        </div>

        {result ? <Popup message={result} /> : null}
      </Form>
      {showCode ? child : null}
    </>
  );
};

export default PaymentUIBuilder;
