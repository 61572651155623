import React from 'react';
import styled from '@emotion/styled';
import { useTheme } from 'emotion-theming';
import { ArrowRight } from 'react-feather';
import { onMobile, onTablet, isMobile } from '../../styles/responsive';

const CardContainer = styled.div`
  display: ${(props) => (props.quickLinks ? 'block' : 'flex')};
  padding: ${(props) => (props.quickLinks ? '0' : '10px')};
  justify-content: space-between;
  ${onMobile} {
    display: block;
  }
`;

export default ({ ...props }) => {
  return <CardContainer {...props} />;
};
