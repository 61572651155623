import * as React from 'react';
import { useRef } from 'react';
import Highlight, { defaultProps } from 'prism-react-renderer';
import theme from 'prism-react-renderer/themes/dracula';
import Loadable from 'react-loadable';
import LoadingProvider from './loading';
import CopyButton from './copyButton';
import styled from '@emotion/styled';
import './tab.css';

const Container = styled.div`
  position: relative;
  max-width: 100%;
  overflow: hidden;
  &:after {
    content: '';
    position: absolute;
    width: 120%;
    bottom: 1px;
    left: -41px;
    display: block;
    z-index: 1111;
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
    box-shadow: 0px 0px 9px 3px #ddfcffb0;
  }
`;
const Overlap = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: #ffffff24;
  display: none;
  cursor: pointer;
`;
const Content = styled.div`
  height: 22em;
  overflow: hidden;
  position: relative;
`;

const ButtonExpand = styled.a`
  position: absolute;
  left: calc(50% - 65px);
  top: calc(50% - 12px);
  padding: 0 12px 0 8px;
  display: block;
  cursor: pointer;
  height: 24px;
  line-height: 24px;
  font-size: 14px;
  background: #616061;
  pointer-events: auto;
  color: #fff;
  border-radius: 12px;
`;

const Buttonshrink = styled.a`
  position: absolute;
  left: calc(50% - 65px);
  top: calc(100% - 25px);
  padding: 0 12px 0 8px;
  display: none;
  cursor: pointer;
  height: 24px;
  line-height: 24px;
  font-size: 14px;
  background: #616061;
  pointer-events: auto;
  color: #fff;
  border-radius: 12px;
`;

const Line = styled.div`
  display: table-row;
`;

const LineNo = styled.span`
  display: table-cell;
  text-align: right;
  padding-right: 1em;
  user-select: none;
  opacity: 0.5;
`;

const LineContent = styled.span`
  display: table-cell;
`;

const copyToClipboard = (str) => {
  const el = document.createElement('textarea');
  el.value = str;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

/** Removes the last token from a code example if it's empty. */
function cleanTokens(tokens) {
  const tokensLength = tokens.length;

  if (tokensLength === 0) {
    return tokens;
  }
  const lastToken = tokens[tokensLength - 1];

  if (lastToken.length === 1 && lastToken[0].empty) {
    return tokens.slice(0, tokensLength - 1);
  }
  return tokens;
}

const LoadableComponent = Loadable({
  loader: () => import('./LiveProvider'),
  loading: LoadingProvider,
});

const properHeight = (tokens) => {
  if (tokens.length - 1 > 21) {
    return '22em';
  } else {
    return 'auto';
  }
};

/* eslint-disable react/jsx-key */
const CodeBlock = ({ children: code, ...props }) => {
  const [isCopied, setIsCopied] = React.useState(false);
  const contentEl = useRef(null);
  const handleExpandButton = (e, tokens, contentEl) => {
    if (
      tokens.length - 1 > 21 &&
      contentEl.current.style.height != 'auto' &&
      contentEl.current.children[0]
    ) {
      contentEl.current.children[0].style.display = 'block';
      contentEl.current.parentNode.parentNode.children[0].style.display = 'block';
    }
  };

  const handleRemoveExpandButton = (e, tokens, contentEl) => {
    if (tokens.length - 1 > 21 && contentEl.current.children[0]) {
      //  e.target.children[0].style.display = 'none';
      contentEl.current.parentNode.parentNode.children[0].style.display = 'none';
    }
  };
  const onClickExpand = (e, contentEl) => {
    contentEl.current.style.height = 'auto';
    e.target.style.display = 'none';
    contentEl.current.parentNode.parentNode.children[0].style.display = 'none';
    contentEl.current.children[1].style.display = 'block';
  };
  const onClickShrink = (e, contentEl) => {
    contentEl.current.style.height = '22em';
    e.target.style.display = 'none';
    contentEl.current.children[0].style.display = 'block';
  };

  // const metaData = JSON.stringify({});
  if (props.configdata) {
    code = code.replace('amount = ', `amount = '${props.configdata.amount}'`);
    code = code.replace('secret = ', `secret = '${props.configdata.apikey}'`);
    code = code.replace('mid = ', `mid = '${props.configdata.mid}'`);
    code = code.replace('orderId = ', `orderId = '${props.configdata.orderId}'`);
    code = code.replace('ORDER-MID', `${props.configdata.mid}`);
    code = code.replace('ORDER-ORDERID', `${props.configdata.orderId}`);
    code = code.replace('ORDER-CURRENCY', `EGP`);
    code = code.replace('ORDER-MODE', `test`);
    code = code.replace(
      'OREDER-ALLOWEDMETHODS',
      `${
        props.configdata.methods == '' ? 'card,bank_installments,wallet' : props.configdata.methods
      }`
    );
    code = code.replace('ORDER-AMOUNT', `${props.configdata.amount}`);
    code = code.replace('ORDER-HASH', `${props.configdata.hash}`);
    code = code.replace('ORDER-FAILUREREDIRECT', `${props.configdata.redirectFailure}`);
    code = code.replace('ORDER-REDIRECTMETHOD', `${props.configdata.redirect}`);
    code = code.replace('ORDER-DISPLAY', `${props.configdata.display}`);
    code = code.replace('ORDER-REDIRECTURL', `${props.configdata.redirectUrl}`);
    code = code.replace('ORDER-BRANDCOLOR', `${props.configdata.brandColor}`);
    code = code.replace(
      'ORDER-HPP-BRANDCOLOR',
      `${encodeURIComponent(props.configdata.brandColor)}`
    );
  }

  if (props['react-live']) {
    return <LoadableComponent code={code} />;
  } else {
    var lang = props.className ? props.className.split('-')[1] : null;
    if (lang === 'iframe' || lang === 'hpp') {
      lang = 'html';
    }
    return (
      <Container>
        <Overlap />
        <CopyButton
          onClick={() => {
            copyToClipboard(code);
            setIsCopied(true);
            setTimeout(() => setIsCopied(false), 3000);
          }}
        >
          {isCopied ? '🎉 Copied!' : 'Copy'}
        </CopyButton>
        <Highlight
          {...defaultProps}
          code={code}
          language={lang ? lang : 'javascript'}
          theme={theme}
        >
          {({ className, style, tokens, getLineProps, getTokenProps }) => (
            <pre
              id="preCodeBlock"
              className={className + ' pre'}
              style={{
                ...style,
                backgroundColor: '#001F5F',
              }}
              p={3}
            >
              <Content
                ref={contentEl}
                style={{ height: properHeight(tokens) }}
                onMouseLeave={(e) => handleRemoveExpandButton(e, tokens, contentEl)}
                onMouseEnter={(e) => handleExpandButton(e, tokens, contentEl)}
              >
                <ButtonExpand
                  onClick={(e) => onClickExpand(e, contentEl)}
                  style={{ display: tokens.length - 1 > 21 ? 'block' : 'none' }}
                >
                  Click here to expand
                </ButtonExpand>
                <Buttonshrink onClick={(e) => onClickShrink(e, contentEl)}>
                  Click here to shrink
                </Buttonshrink>
                {cleanTokens(tokens).map((line, i) => {
                  let lineClass = {};

                  let isDiff = false;

                  if (line[0] && line[0].content.length && line[0].content[0] === '+') {
                    lineClass = { backgroundColor: 'rgba(76, 175, 80, 0.2)' };
                    isDiff = true;
                  } else if (
                    line[0] &&
                    line[0].content.length &&
                    line[0].content[0] === '-' &&
                    lang != 'curl'
                  ) {
                    lineClass = { backgroundColor: 'rgba(244, 67, 54, 0.2)' };
                    isDiff = true;
                  } else if (
                    line[0] &&
                    line[0].content === '' &&
                    line[1] &&
                    line[1].content === '+'
                  ) {
                    lineClass = { backgroundColor: 'rgba(76, 175, 80, 0.2)' };
                    isDiff = true;
                  } else if (
                    line[0] &&
                    line[0].content === '' &&
                    line[1] &&
                    line[1].content === '-'
                  ) {
                    lineClass = { backgroundColor: 'rgba(244, 67, 54, 0.2)' };
                    isDiff = true;
                  }
                  const lineProps = getLineProps({ line, key: i });

                  lineProps.style = lineClass;
                  const diffStyle = {
                    userSelect: 'none',
                    MozUserSelect: '-moz-none',
                    WebkitUserSelect: 'none',
                  };

                  let splitToken;

                  return (
                    <Line {...lineProps} key={line + i}>
                      <LineNo>{i + 1}</LineNo>
                      <LineContent>
                        {line.map((token, key) => {
                          if (isDiff) {
                            if (
                              (key === 0 || key === 1) &
                              (token.content.charAt(0) === '+' || token.content.charAt(0) === '-')
                            ) {
                              if (token.content.length > 1) {
                                splitToken = {
                                  types: ['template-string', 'string'],
                                  content: token.content.slice(1),
                                };
                                const firstChar = {
                                  types: ['operator'],
                                  content: token.content.charAt(0),
                                };

                                return (
                                  <React.Fragment key={token + key}>
                                    <span
                                      {...getTokenProps({ token: firstChar, key })}
                                      style={diffStyle}
                                    />
                                    <span {...getTokenProps({ token: splitToken, key })} />
                                  </React.Fragment>
                                );
                              } else {
                                return (
                                  <span {...getTokenProps({ token, key })} style={diffStyle} />
                                );
                              }
                            }
                          }
                          return <span {...getTokenProps({ token, key })} />;
                        })}
                      </LineContent>
                    </Line>
                  );
                })}
              </Content>
            </pre>
          )}
        </Highlight>
      </Container>
    );
  }
};

export default CodeBlock;
