import defaultColors from './colors';

const colors = {
  ...defaultColors,

  primary: defaultColors.green,
  primaryDark: defaultColors.blueDark,
  font: '#333334',
  fontTable: '#333334',
  fontDark: '#121213',
  background: '#F5F7F9',
  backgroundCode:'#F5F7F9',
  mainBackground: '#fefefe',
  border: 'rgba(237, 236, 238, 0.8)',
  hover: '#00bcbc6e',
  anchor: '#0080F9',
  shadow: defaultColors.gray + '33',
};

export default {
  colors: colors,
};
