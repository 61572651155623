import React, { useEffect } from 'react';
import styled from '@emotion/styled';

const Pop = styled.div`
  .btn {
    border: none;
    padding: 1rem 2rem;
    border-radius: 100vh;
    background-image: linear-gradient(to right, #29e2be, #00bcbc);
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: flex;
    margin: 5% auto;
    cursor: pointer;
  }
  .popup {
    &__wrapper {
      position: fixed;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.2);
      width: 100%;
      height: 100%;
      display: none;
    }

    &__content {
      background-color: #eee;
      padding: 1rem;
      text-align: center;
      margin: 5% auto;
      width: 100%;
      max-width: 500px;
      position: relative;
    }

    &__close {
      position: absolute;
      top: 1rem;
      right: 1rem;
      border: none;
      background-color: #0c5547;
      color: #fff;
      padding: 0.2rem 0.4rem;
      width: auto;
      margin: 0;
      cursor: pointer;
    }

    &__details p:first-of-type {
      font-size: 2rem;
      font-family: sans-serif;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 22px;
      margin: 10px;
      color: #0c5547;
    }

    &__details p {
      text-transform: uppercase;
      font-family: sans-serif;
      margin: 10px;
    }

    &__btn {
      padding: 0.5rem 1rem;
      margin: 0 auto;
    }
    &__connect {
      text-align: center;
      display: inline-block;
    }
  }
`;

const Popup = ({ message }) => {
  useEffect(() => {
    const button = document.querySelector('.btn');
    const close = document.querySelector('.popup__close');
    const popup = document.querySelector('.popup__wrapper');

    popup.style.display = 'block';

    close.addEventListener('click', () => {
      popup.style.display = 'none';
    });

    popup.addEventListener('click', () => {
      popup.style.display = 'none';
    });
  }, []);
  return (
    <Pop>
      <div className="popup__wrapper">
        <div className="popup__content">
          <button className="popup__close">x</button>
          <div className="popup__details">
            <p>Payment Status : {message.status}</p>
            <p>Transaction ID : {message.transactionId}</p>
            <a
              href="https://kashier.io/en/contact-us"
              className="popup__btn popup__connect btn"
              target="blank"
            >
              This is time to get touch with us
            </a>
          </div>
        </div>
      </div>
    </Pop>
  );
};

export default Popup;
