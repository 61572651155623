import React from 'react';
import styled from '@emotion/styled';
import { onMobile } from '../../styles/responsive';
import Link from '../Link';
import './style.css';
import './style.css';
import { css } from '@emotion/core';
import { useTheme } from 'emotion-theming';

const activeNode = (theme) => css`
  color: ${theme.header.font.hover} !important;
  :before {
    visibility: visible !important;
    transform: scaleX(1) !important;
  }
`;
const Navigation = styled(({ className, links }) => {
  const theme = useTheme();
  let path = typeof window !== 'undefined' ? window.location.href : '';
  return (
    <nav css={{ display: 'flex' }}>
      <ul className={className} id="select">
        {links
          ? links.map((link, key) => {
              const openRule = link.external ? '_blank' : '_self';
              if (link.link !== '' && link.text !== '') {
                return (
                  <li key={key}>
                    <Link
                      activeClassName="active"
                      // style={
                      //   path.search('/payment/') > -1 && key == 1 ? { color: '#00bcbc' } : null
                      // }
                      css={
                        path.search('/payment/') > -1 && key == 1
                          ? activeNode(theme)
                          : path.search('/editing/') > -1 && key == 2
                          ? activeNode(theme)
                          : ''
                      }
                      to={link.link}
                      target={openRule}
                      rel="noopener"
                      dangerouslySetInnerHTML={{ __html: link.text }}
                    />
                  </li>
                );
              }
            })
          : null}
      </ul>
    </nav>
  );
})`
  display: flex;
  align-items: center;
  -webkit-overflow-scrolling: touch;
  float: left;
  ${onMobile} {
    width: 100%;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin-top: 10px;
    li {
      height: 37px;

      a {
        font-size: 14px;
        padding: 10px 15px;
      }
    }
  }
  li {
    list-style-type: none;
    display: flex;
    & > a:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 3px;
      bottom: 0;
      left: 0;
      background: ${(props) => props.theme.header.font.hover};
      visibility: hidden;
      border-radius: 4px;
      transform: scaleX(0);
      transition: 0.25s linear;
    }
    & > a:hover:before {
      visibility: visible;
      transform: scaleX(1);
    }
    a {
      font-family: 'Roboto';
      position: relative;
      color: ${(props) => props.theme.header.font.base};
      font-size: 16px;
      font-weight: 500;
      line-height: 1em;
      opacity: 1;
      padding: 10px 15px;
      &:hover {
        color: ${(props) => props.theme.header.font.hover};
      }
    }

  }
  }
`;

export default Navigation;
