import React, { useState, useEffect } from 'react';
import './style.css';
export const Tabs = (props) => {
  const [selected, setSelected] = useState(0);
  const [child, setChild] = useState(null);

  const handleChange = (index) => {
    setSelected(index);
  };

  useEffect(() => {
    if (props.children[selected].props.configdata) {
      setChild({
        ...props.children[selected],
        props: {
          ...props.children[selected].props,
          children: {
            ...props.children[selected].props.children,
            props: {
              ...props.children[selected].props.children.props,
              configdata: props.children[selected].props.configdata,
            },
          },
        },
      });
    }
  }, [selected, props.children[selected].props.configdata]);
  console.log();
  return (
    <>
      {props.title ? (
        <p
          style={{
            fontWeight: '600',
            fontSize: '18px',
            textAlign: 'left',
            marginBottom: '16px',
            marginTop: '32px',
          }}
        >
          {props.title}
        </p>
      ) : null}
      <div>
        <ul className="navTab">
          {props.children.map((elem, index) => {
            let style = index === selected ? 'selected' : '';
            var lang = elem.props.children.props.className
              ? elem.props.children.props.className.split('-')[1]
              : null;
            if (lang === 'js') {
              lang = 'node';
            }
            return (
              <li key={index} className={style} onClick={() => handleChange(index)}>
                {lang.toUpperCase()}
              </li>
            );
          })}
        </ul>
        <div className="tab">{child ? child : props.children[selected]}</div>
      </div>
    </>
  );
};

export default Tabs;
