module.exports = {
    "metadata": {
        "name": "",
        "short_name": "",
        "description": "Kashier - The payments infrastructure for Egyptian business.",
        "language": "en",
        "url": "http://localhost",
        "pathPrefix": "/",
        "gaTrackingId": null,
        "siteImage": null,
        "favicon": "/assets/kashier.jpg",
        "themeColor": "#ff0000"
    },
    "header": {
        "logo": "/assets/kashier.png",
        "logoLink": "/",
        "helpUrl": "",
        "links": null,
        "enabled": true
    },
    "sidebar": {
        "enabled": true,
        "ignoreIndex": true,
        "forcedNavOrder": [],
        "expanded": [],
        "groups": [
            {
                "order": 1,
                "path": "/gettingstarted",
                "title": "Getting Started"
            },
            {
                "order": 2,
                "path": "/dashboardapi",
                "title": "Dashboard API"
            },
            {
                "order": 3,
                "path": "/features",
                "title": "Features"
            },
            {
                "order": 4,
                "path": "/payment",
                "title": "Payment"
            },
            {
                "order": 5,
                "path": "/ecommerce",
                "title": "E-Commerce"
            },
            {
                "order": 6,
                "path": "/tools",
                "title": "Tools"
            },
            {
                "order": 7,
                "path": "/directAPIintegration",
                "title": "Direct API Integration"
            },
            {
                "order": 8,
                "path": "/payout",
                "title": "Payout"
            },
            {
                "order": 9,
                "path": "/POS",
                "title": "POS"
            }
        ],
        "links": [
            {
                "text": "Kashier",
                "link": "https://merchant.kashier.io"
            }
        ],
        "poweredBy": {
            "trademark": "/assets/kashier.jpg",
            "name": "Kashier",
            "link": "https://merchant.kashier.io"
        }
    },
    "pwa": {
        "manifest": {
            "name": "Kashier Payment Solutions",
            "short_name": "Kashier",
            "start_url": "/",
            "background_color": "#6b37bf",
            "theme_color": "#6b37bf",
            "display": "standalone",
            "crossOrigin": "anonymous",
            "icon": "static/assets/favicon.jpg",
            "description": "Kashier - The payments infrastructure for Egyptian business.",
            "cache_busting_mode": "none",
            "include_favicon": false,
            "lang": "en"
        },
        "enabled": true
    },
    "social": {
        "facebook": "",
        "github": "https://github.com/Kashier-payments",
        "gitlab": "",
        "instagram": "",
        "linkedin": "",
        "mail": "",
        "gmail": "",
        "slack": "",
        "twich": "",
        "twitter": "",
        "youtube": ""
    },
    "features": {
        "editOnRepo": {
            "editable": true,
            "location": "https://github.com/Kashier-payments",
            "type": "github"
        },
        "search": {
            "enabled": true,
            "indexName": "docs",
            "algoliaAppId": null,
            "algoliaSearchKey": null,
            "algoliaAdminKey": null,
            "excerptSize": 10000,
            "engine": "localsearch",
            "localSearchEngine": {},
            "placeholder": "Search here...",
            "startComponent": "input",
            "debounceTime": 380,
            "snippetLength": 22,
            "hitsPerPage": 10,
            "showStats": true,
            "pagination": {
                "enabled": true,
                "totalPages": 10,
                "showNext": true,
                "showPrevious": true
            }
        },
        "toc": {
            "show": true,
            "depth": 4
        },
        "previousNext": {
            "enabled": true,
            "arrowKeyNavigation": true
        },
        "scrollTop": true,
        "showMetadata": false,
        "propagateNetlifyEnv": true,
        "pageProgress": {
            "enabled": true,
            "excludePaths": [
                "/"
            ],
            "height": 3,
            "prependToBody": false,
            "color": "#A05EB5",
            "includePaths": [
                "/configuration/settingup/features"
            ]
        },
        "mermaid": {
            "language": "mermaid",
            "theme": "light",
            "options": {},
            "width": 400,
            "height": 300
        },
        "rss": {
            "enabled": true,
            "showIcon": false,
            "title": "Kashier Payment Solutions Docs",
            "copyright": "2021, Kashier",
            "webMaster": "Mohamed Khaled",
            "managingEditor": "Mohamed Khaled",
            "categories": [
                "Docs as Code",
                "Kashier"
            ],
            "ttl": 60,
            "matchRegex": "^/",
            "outputPath": "/rss.xml",
            "generator": "Kashier"
        },
        "darkMode": {
            "enabled": true,
            "default": false
        },
        "publishDraft": false,
        "fullScreenMode": {
            "enabled": true,
            "hideHeader": true,
            "hideToc": true,
            "hideSidebar": true
        }
    }
};